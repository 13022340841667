














































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useMembershipCard } from '@/admin/membershipCard';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcDate from '@/components/FcDate.vue';
import FcColor from '@/components/FcColor.vue';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import router from '@/router';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'MembershipCard',
  components: {
    FcDate,
    FcColor,
    FcImage,
    FcStaticImage,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    membershipCardId: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup({ membershipCardId }) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('membershipcards'));

    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const { membershipCard } = useMembershipCard(membershipCardId);
    const isSaving = ref(false);

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        if (membershipCardId) {
          await membershipCard.saveMembershipCard(membershipCardId);
          notification.notify('変更しました。');
        } else {
          await membershipCard.createDefaultCard();
          notification.notify('作成しました。');
          router.push('/membershipcards');
        }
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    return {
      pageTitle: 'デフォルト会員証',
      myRoleSettings,
      membershipCard,
      save,
      isSaving,
    };
  },
});
